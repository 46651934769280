* {
	margin: 0;
	padding: 0;
	font-family: "Bebas Neue", cursive;
	font-family: "Lora", serif;
	font-family: "Montserrat", sans-serif;
	font-family: "Nunito", sans-serif;
	font-family: "Raleway", sans-serif;
	overflow-x: hidden;
	scroll-behavior: smooth;
}
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #00010d;
	/* border-radius: 100px; */
}

::-webkit-scrollbar-thumb {
	background-color: #3b403f;
	height: 100px;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #252727;
}

.video-background {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.video-background video {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	overflow: hidden;
	filter: brightness(0.5);
}
.App {
	width: 100%;
	height: auto;
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.content, .calc{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}
.calc{
	margin-bottom: 10px;
	top: 80px;
	color: black;
	background-color: white;
}
.calc-form{
	/* border: 1px red solid; */
	width: 450px;
}
.calc-form > *{
	margin-bottom: 15px;
}
.content h1 {
	font-size: 110px;
}

.responsive-content {
	display: none;
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 600px;
	justify-content: space-between;
	margin-top: 2rem;
}
.buttons::-webkit-scrollbar {
	display: none;
}

@media screen and (max-width: 940px) {
	.content {
		font-size: smaller;
		width: 30%;
		text-align: center;
		display: none;
	}
	.video-background video {
		width: 100vw;
		overflow-x: hidden;
		height: auto;
	}

	.responsive-content {
		display: block;
		width: 100%;
		height: 30vh;
		color: white;
		position: relative;
		overflow: visible;
		box-shadow: inset 0px -10px 10px -10px rgb(0, 0, 0);
	}

	.responsive-content h1 {
		font-size: 30px;
	}

	.debord {
		position: absolute;
		top: -60px;
		margin: 1rem;
	}
	.carroussel {
		height: 20vh;
	}
}
