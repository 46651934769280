.about {
	width: 100%;
	height: 60vh;
	display: flex;
	flex-direction: row;
	color: white;
	background-color: black;
	align-items: center;
	justify-content: center;
	overflow: visible;
}
.about ::-webkit-scrollbar {
	display: none;
}
.about img {
	width: 350px;
	margin: 3rem;
	filter: brightness(0.8);
}
.img-holder {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	z-index: 30;
}

.descripiton {
	width: 40rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-right: 12rem;
}
.descripiton-left {
	width: 40rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-left: 4rem;
}

.descripiton h1 {
	margin-bottom: 20px;
}
.img-holder {
	width: 40%;
}
span {
	text-align: justify;
	margin-bottom: 5px;
	font-size: 20px;
}
.responsive-description {
	display: none;
}

@media screen and (max-width: 940px) {
	.img-holder {
		display: none;
	}
	.descripiton {
		display: flex;
		width: 100%;
		margin-left: 20px;
		margin-right: 20px;
		flex-direction: column;
		font-size: 12px;
		overflow-y: hidden;
	}
	.descripiton-left {
		display: none;
	}
	.about {
		background-color: transparent;
		/* border-top: 0.6px solid white; */
		/* border-bottom: 0.6px solid white; */
		font-size: small;

		height: 45vh;
		background-color: black;
	}
	.responsive-description {
		display: block;
		padding: 1.5rem;
		margin-bottom: 40px;
	}

	.descripiton {
		display: none;
	}
}
