.box {
	height: 440px;
	width: 300px;
	overflow: hidden;
	color: white;
}

.background-img {
	height: 100%;
	width: 80%;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.holder {
	width: 92vw;
}

.slide-content {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.slick-slide div {
	width: 370px;
}
.slick-slider {
	overflow-y: hidden;
}

@media screen and (max-width: 940px) {
	.slick-slide div {
		width: 380px;
		margin: 0;
	}
	.holder {
		width: 98vw;
	}
}
