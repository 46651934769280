.footer {
	background: linear-gradient(rgba(0, 0, 0, 0.856), rgba(0, 0, 0, 0.329));
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-around;
	bottom: 0;
	z-index: 40;
	color: white;
	height: 21vh;
	width: 100%;
}
.footer ::-webkit-scrollbar {
	display: none;
}
.footer h4 {
	margin-bottom: 10px;
}
.footer-navigation {
	width: 25%;
	height: 80%;
	display: flex;
	flex-direction: column;
	font-size: 10;
	text-align: left;
}
.footer-contact {
	width: 25%;
	height: 80%;
	display: flex;
	flex-direction: column;
	font-size: 10;
	text-align: left;
}

div span {
	font-size: 11px;
	padding: 2px;
}
.social-media {
	display: flex;
	height: 70%;
	align-items: center;
}
@media screen and (max-width: 940px) {
	.footer {
		height: 20vh;
		/* flex-direction: column; */
	}
	.footer-contact {
		width: 35%;
	}
	.infos {
		height: 40vh;
		box-shadow: 0 8px -12px -8px rgba(0, 0, 0, 0.6);
	}
	.footer-navigation {
		display: none;
	}
}
