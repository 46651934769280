.process {
	width: 100%;
	height: 60vh;
	background-color: transparent;
	background-image: linear-gradient(rgba(0, 0, 0, 0.669), rgba(0, 0, 0, 0.555)),
		url(https://images.unsplash.com/photo-1542202229-ab9163c560ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.method {
	width: 90%;
	height: 20vh;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

.single ::-webkit-scrollbar {
	display: none;
}

.single {
	width: 30%;
	height: 7rem;
	background-color: rgba(0, 0, 0, 0.678);
	border: 1px solid rgba(35, 88, 35, 0.555);
	border-radius: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.single span {
	text-align: center;
}
.single h4 {
	color: #bd5a21;
}
@media screen and (max-width: 940px) {
	.single spam {
		font-size: 6;
	}
	.method {
		display: flex;

		width: 100%;
		height: 50vh;

		flex-direction: column;
	}
	.single {
		width: 80%;
		height: 10rem;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
