.header {
	position: absolute;
	height: 70px;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	transition: 0.3s ease-in;
	overflow: hidden;
	background-color: transparent;
	overflow: hidden;
}
.navbar::-webkit-scrollbar {
	display: none;
}
.logo {
	width: 100px;
	height: 95px;
	background-image: url(../assets/logo.png);
	background-position: center;
	background-size: cover;
}
.header .navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1240px;
	margin: auto;
	height: 100%;
	padding: 0 1rem;
}

.header .nav-menu a {
	color: #ffffff;
}

.header .navbar img {
	width: 250px;
	height: auto;
}

.header .nav-menu {
	display: flex;
}

.header .nav-item {
	padding: 1rem;
	font-weight: 500;
}

.header .nav-item :hover {
	padding-bottom: 12px;
	border-bottom: 3px solid #9fc131;
}

.hamburger {
	display: none;
}

@media screen and (max-width: 940px) {
	.header {
		max-width: 100%;
		background-color: rgba(0, 0, 0, 0.9);
		position: static;
	}

	.header .navbar {
		max-width: 100%;
	}

	.hamburger {
		display: block;
	}

	.nav-menu {
		position: fixed;
		left: -100%;
		top: 59px;
		flex-direction: column;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.9);
		width: 100%;
		height: 100vh;
		z-index: 999;
		text-align: center;
		transition: 0.3s;
	}

	.nav-menu.active {
		left: 0;
	}

	.nav-item {
		margin: 1.5rem 0;
	}

	.header .navbar img {
		width: 150px;
	}
}

ul {
	list-style: none;
}
a {
	text-decoration: none;
}
